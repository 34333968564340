/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Chat = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateChatItem;
    var $templateChatMessageItem;
    var prevDate;
    var prevSender;
    let loadedUsers = [];

    /* ----- Init Attendees -------------------------------------------------------------------------------------------- */
    function Init() {
        $templateChatItem = $('template#tplChatItem').remove().contents();
        $templateChatMessageItem = $('template#tplChatMessageItem').remove().contents();

        localState.chat = localState.chat || {lastMsgRead: {}, active: ""};
        localState.chat.page = 0;

        $(document).on('input', '#chat .search-wrapper input', evtInput);
        $(document).on('keyup', '#chat .search-wrapper input', evtPressEnter);
        $(document).on('click', '#chat .search-wrapper .btn-clear', evtClear);
        $(document).on('click', '#chat #chatList .item', evtClick);
        $(document).on('click', '#chat #chatUsersList .item', evtClick);
        $(document).on('submit', '#chat-details form', sendMessage);
        $(document).on('click', '#chat-details .add_user', addUser);
        $(document).on('click', '#chat-details .close-add-user', closeAddUser);
        $(document).on('click', '#chat-details .video-chat', startVideoChat);

        $(document).on('click', '#chat-details #chatAddList .item', evtClickAdd);
        $(document).on('input', '#chat-details .searchadd-wrapper input', evtInputAdd);
        $(document).on('keyup', '#chat-details .searchadd-wrapper input', evtPressEnter);
        $(document).on('click', '#chat-details .searchadd-wrapper .btn-clear', evtClearAdd);

        $(document).on('click', '#chat #chatUsersList .load-more', loadMore);

        clientEventListeners.push({
            type: 'open',
            event_name: 'chat_opened'
        });

        clientEventListeners.push({
            type: 'swap',
            event_name: 'chat_opened'
        });

        $(document).on('chat_opened', function (){
            $('.floatbadge.general-unread').remove();
        });

        $(window).on('resize', resizeW);

        $("#chat-details article").replaceWith($("#chat-details article").clone());

        loadedUsers = [];

        loadMore();
        loadStarted();
        //sortList();
        //renderList(true);

        $.each(AppData.chatrooms, function (i, el) {
            let lastread = localState.chat.lastMsgRead[el.id] || 0;

            if (el.last_message > (lastread / 1000)) {
                $('#landing [data-target="chat"]').addClass("badge");

                $("#chat .chatList [data-roomid='"+el.id+"']").addClass("badge");

                let exists = $('.floatbadge.general-unread');

                if (exists.length == 0) {
                    let floatbadge = $("<div/>").addClass("floatbadge border-primary general-unread");
                    floatbadge.html(Language.getItem("unread-messages"));
                    floatbadge.on("click", function () {
                        Layout.show("chat");
                        floatbadge.remove();
                    });
                    floatbadge.appendTo($("body"));
                }
            }
        });
    }

    function refresh() {
        localState.chat = localState.chat || {lastMsgRead: {}, active: ""};
        localState.chat.page = 0;

        loadedUsers = [];

        loadMore();
        loadStarted();
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortList() {

        AppData.chatrooms.sort(function (a, b) {
            if (a.last_message === undefined) {
                a.last_message = 0;
            }
            if (b.last_message === undefined) {
                b.last_message = 0;
            }

            return (b.last_message - a.last_message);
        });
    }

    function startVideoChat() {
        writeDbLog("video chat started", "","user_logs", "chat");
        var roomid = $("#chat-details [name='room_id']").val();

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'createTrueConfChatRoom',
                chat_id: roomid,
                server: 'chat'
            },
            success: function (response) {
                openTrueConfCall(response.room_id, 'chat');

                if (response.rooms != undefined && response.msg != undefined) {
                    //showChat(roomid);
                    AppData.chatrooms = response.rooms;
                    var room = AppData.chatrooms.find(function (r) {
                        return r.id == roomid;
                    });
                    if (room) {
                        appendMessage(response.msg);
                        response.msg.scroll = true;
                        for (var u in room.users) {
                            Socket.Send("Chat.BuildFromSource", null, {
                                uid: room.users[u]
                            });
                            Socket.Send("Chat.appendMessage", response.msg, {
                                uid: room.users[u]
                            });
                        }
                    }
                }
            }
        });
    }

    function addUser() {

        if (AppData.settings.homeScreen == "floating") {
            $(".add_user_to_room .add_user_to_room_inner").addClass("floating");
        } else {
            $(".add_user_to_room .add_user_to_room_inner").removeClass("floating");
        }

        $(".add_user_to_room").fadeIn(400);
    }

    function closeAddUser() {
        $(".add_user_to_room").fadeOut(400, function () {
            $(".add_user_to_room .searchadd-wrapper .btn-clear").trigger("click");
        });
    }

    /* ----- Build Attendees List -------------------------------------------------------------------------------------- */
    function renderList(add_badge) {
        var
            $self = $('#chat #chatList'),
            $fragmentChat = $(document.createDocumentFragment());

        var has_badge = [];
        $("#chat #chatList .item.badge").each(function () {
            has_badge.push($(this).attr("data-roomid"));
        });

        var chat_started = 0;

        $.each(AppData.chatrooms, function (i, el) {

            var $clone = $templateChatItem.clone();
            var info = [];

            $clone.attr('data-roomid', el.id);
            $clone.addClass("started");

            var users_in_chat = [];
            for (var ru in el.users) {
                if (el.users[ru] != AppData.profile.id) {
                    var u = AppData.chatusers.find(function (u_el) {
                        return u_el.id == el.users[ru];
                    });

                    if (u) {
                        users_in_chat.push(u.name[localState.lang]);
                    }
                }
            }

            $clone.find('.data-name').append(users_in_chat.join(", "));

            if (el.last_message_text != "") {
                $clone.find('.data-info').html(el.last_message_text);
            }

            $clone.appendTo($fragmentChat);

            if (has_badge.indexOf(el.id) > -1 ||
                (add_badge && (has_badge.indexOf(el.id) > -1
                        || (localState.chat.lastMsgRead[el.id] / 1000) < el.last_message
                        || ($.isEmptyObject(localState.chat.lastMsgRead) && el.last_message > 0)
                        || (localState.chat.lastMsgRead[el.id] == undefined && el.last_message > 0)
                    ) && el.last_message != el.sent_message
                )
            ) {
                $clone.addClass("badge");
                $('#landing [data-target="chat"]').addClass("badge");
            }

            if (el.last_message == 0 && el.sent_message == 0) {
                //$clone.toggle(false);
            } else {
                chat_started++;
            }
        });
        $self.html($fragmentChat);

        $('#chat .no-result').toggle(chat_started == 0);

        if (AppData.modulsettings.chat_allowed_only && AppData.profile.chat_allowed == 0) {
            $("#chat .search-wrapper").addClass("hidden");
            $("#chat .more-cont").addClass("hidden");
        } else {
            $("#chat .search-wrapper").removeClass("hidden");
            $("#chat .more-cont").removeClass("hidden");
        }
    }

    function showMore() {
        var
            $self = $('#chat #chatUsersList'),
            $fragmentChat = $(document.createDocumentFragment());

        $.each(loadedUsers, function (i, el) {

            let in_room = AppData.chatrooms.find(function (item) {
                return item.users.indexOf(el.id) > -1;
            });


            if (el.id != AppData.profile.id && in_room == undefined) {
                var $clone = $templateChatItem.clone();
                var info = [];
                if (el.job[localState.lang])
                    info.push(el.job[localState.lang]);
                if (el.company[localState.lang])
                    info.push(el.company[localState.lang]);
                $clone.attr('data-index', el.id);
                $clone.find('.data-name').text(el.name[localState.lang]);
                //$clone.find('.data-info').text(info.join(' | ')).toggleClass('hidden', !info.length);

                $clone.find('.data-info').html(Language.getItem("start-conversation"));

                if (el.img) {
                    $clone.find('.data-img').attr(($self.is(':visible')) ? 'src' : 'data-src', 'upload/users/' + el.img);
                } else {
                    $clone.find('.data-img').attr('src', 'img/profile-picture-placeholder.png');
                }
                $clone.appendTo($fragmentChat);

            }
        });

        let morecont = $("<div/>").addClass("more-cont");
        let morebtn = $("<button/>").addClass("btn load-more").html(Language.getItem("load-more"));

        morebtn.appendTo(morecont);
        morecont.appendTo($fragmentChat);

        $self.html($fragmentChat);
    }

    function loadMore() {
        $("#chat #chatUsersList .load-more").addClass("disabled").prop("disabled", true);

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: "loadMoreChatUsers",
                page: localState.chat.page,
                lang: localState.lang,
                started: 0
            },
            success: function (response) {
                $("#chat #chatUsersList .load-more").removeClass("disabled").prop("disabled", false);
                localState.chat.page++;

                loadedUsers = loadedUsers.concat(response.chatusers);

                showMore();

            }, error: function () {
                $("#chat #chatUsersList .load-more").removeClass("disabled").prop("disabled", false);
            }
        });
    }

    function loadStarted() {

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: "loadMoreChatUsers",
                page: 0,
                lang: localState.lang,
                started: 1
            },
            success: function (response) {
                AppData.chatusers = response.chatusers;

                sortList();
                renderList(true);

                if (localState.chat.active != "" && localState.chat.active != undefined) {
                    renderDetails(localState.chat.active);
                }

            }, error: function () {
            }
        });
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails(room_id, uid) {
        if (AppData.modulsettings.showvideochat) {
            $("#chat-details .video-chat").show();
        } else {
            $("#chat-details .video-chat").hide();
        }

        if (AppData.modulsettings.transparent_chat) {
            $("#chat-details .container").addClass("transparent");
            $("#chat-details .sendmessage_inner").addClass("transparent");
        } else {
            $("#chat-details .container").removeClass("transparent");
            $("#chat-details .sendmessage_inner").removeClass("transparent");
        }

        $("#chat-details .messages").empty().attr("data-roomid", room_id);

        $('#chat .search-wrapper input').val('');
        $('#chat .search-wrapper .btn-clear').toggle(false);
        $('#chat .no-result').toggle(false);
        //$("#chat .search-wrapper .btn-clear").trigger("click");

        prevDate = undefined;

        //get previous messages
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'getMessages',
                room_id: room_id
            },
            success: function (response) {
                for (var i in response) {
                    var o = {self: true, scroll: false};
                    o.message = response[i].message;
                    o.type = response[i].type;
                    o.added_users = response[i].added_users;
                    o.sent_at = response[i].sent_at;
                    o.room_id = response[i].room_id;
                    if (response[i].sender != AppData.profile.id) {
                        o.sender = response[i].sender;
                    }
                    appendMessage(o);
                }

                scrollChat(0);
            }
        });

        if ($('#chat .item.badge').length === 0) {
            $('#landing [data-target="chat"]').removeClass("badge");
        }

        $('#chat-details [name="room_id"]').val(room_id);

        var room = AppData.chatrooms.find(function(r) {
            return r.id == room_id;
        });

        var users_in_chat = [];
        if (room) {
            for (var ru in room.users) {
                if (room.users[ru] != AppData.profile.id) {
                    var u = AppData.chatusers.find(function (u_el) {
                        return u_el.id == room.users[ru];
                    });
                    users_in_chat.push(u.name[localState.lang]);
                }
            }
        }

        //$clone.find('.data-name').append(users_in_chat.join(", "));
        $("#chat-details header .data-label").html(users_in_chat.join(", "));

        resizeW();

    }

    function resizeW() {

        var h;

        if ($(".videostream #playerElement").is(":visible") && $(window).width() < 1200) {
            h = $(".videostream #playerElement").height() + 70
        } else {
            h = 63;
        }

        $("#chat-details .sendmessage").css({
            "flex": "0 0 "+h+"px"
        });

    }

    function sendMessage(e) {
        e.preventDefault();

        if ($('input[name="message"]', $("#chat-details")).val() == "") {
            return;
        }

        var f = new FormData($(this).get(0));
        var fdata = $(this).serialize();

        $('input[name="message"]', $("#chat-details")).val('');

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'saveMessage',
                room_id: f.get("room_id"),
                message: f.get("message")
            }, success: function (resp) {
                let message = {message: f.get("message"), "room_id": f.get("room_id"), "sent_at": parseInt(new Date().getTime() / 1000), self: true, scroll: true};
                appendMessage(message);
                for (var r in resp.users) {
                    Socket.Send("Chat.appendMessage", {message: f.get("message"), "room_id": f.get("room_id"), "sent_at": parseInt(new Date().getTime() / 1000), "sender": AppData.profile.id, self: false, scroll: true}, {
                        "uid": resp.users[r]
                    });
                }

                for (var c = 0; c < AppData.chatrooms.length; c++) {
                    if (AppData.chatrooms[c].id == f.get("room_id")) {
                        AppData.chatrooms[c].last_message = new Date().getTime() / 1000;
                        break;
                    }
                }

                sortList();
                renderList(false);

                $('#chat #chatList [data-roomid="'+message.room_id+'"] .data-info').html(message.message);
            }
        });
    }

    function appendMessage(message) {
        var $new_item = $templateChatMessageItem.clone();

        var parsed_date = new Date(message.sent_at * 1000);

        var formatted_date = parsed_date.getFullYear() +"-"+ (parsed_date.getMonth() + 1 < 10 ? '0'+(parsed_date.getMonth()+1) : (parsed_date.getMonth()+1)) + '-' + (parsed_date.getDate() < 10 ? '0'+parsed_date.getDate() : parsed_date.getDate())
        var formatted_time = (parsed_date.getHours() < 10 ? '0'+parsed_date.getHours() : parsed_date.getHours()) + ':' + (parsed_date.getMinutes() < 10 ? '0'+parsed_date.getMinutes() : parsed_date.getMinutes())

        if (prevDate == undefined || prevDate != formatted_date) {
            $('#chat-details .messages[data-roomid="'+message.room_id+'"]').append('<div class="msg_date"><span class="bg-primary color-accent">'+formatted_date+'</span></div>');
        }

        if (message.type == "user_added") {
            var adder = AppData.chatusers.find(function (auser) {
                return auser.id == message.sender;
            });
            var adder_name = "";
            if (adder) {
                adder_name = adder.name[localState.lang];
            }

            if (message.sender == undefined) {
                adder_name = AppData.profile.name[localState.lang];
            }
            var users_names = [];
            for (var au in message.added_users) {
                var added = AppData.chatusers.find(function (auser) {
                    return auser.id == message.added_users[au];
                });
                users_names.push(added.name[localState.lang]);
            }
            var msg = Language.getItem("chat-user-added");
            msg = msg.replace('{adder}', adder_name);
            msg = msg.replace('{added}', users_names.join(", "));
            message.message = msg;
            $new_item.addClass("user_added");
            $new_item.find(".msg").html('<div class="small_add">' + formatted_time + '</div>' + message.message);
            $('#chat-details .messages[data-roomid="' + message.room_id + '"]').append($new_item);
            if (message.scroll) {
                scrollChat(400);
            }
            prevDate = formatted_date;
            return;
        } else if (message.type == "video_chat_started") {

            var adder = AppData.chatusers.find(function (auser) {
                return auser.id == message.sender;
            });
            var adder_name = "";
            if (adder) {
                adder_name = adder.name[localState.lang];
            }

            if (message.sender == undefined) {
                adder_name = AppData.profile.name[localState.lang];
            }

            var msg = Language.getItem("chat-videochat-started");
            msg = msg.replace('{adder}', adder_name);

            message.message = msg;
            $new_item.addClass("user_added video_chat_info");
            $new_item.find(".msg").html('<div class="small_add">' + formatted_time + '</div>' + message.message);
            $('#chat-details .messages[data-roomid="' + message.room_id + '"]').append($new_item);
            if (message.scroll) {
                scrollChat(400);
            }
            prevDate = formatted_date;
            return;

        } else {
            $new_item.find(".msg").html(message.message + '<div class="small">' + formatted_time + '</div>');
        }
        if (message.sender) {

            var user = AppData.chatusers.find(function (el) {
                return el.id == message.sender;
            });

            if (!message.self) {

                for (var c = 0; c < AppData.chatrooms.length; c++) {
                    if (AppData.chatrooms[c].id == message.room_id) {
                        AppData.chatrooms[c].last_message = new Date().getTime() / 1000;
                        break;
                    }
                }

                sortList();
                renderList(true);

                $('#landing [data-target="chat"]').addClass("badge");


                if (!$('#chat-details .messages[data-roomid="'+message.room_id+'"]').is(":visible")) {
                    $('#chat [data-roomid="' + message.room_id + '"]').addClass("badge");
                    $('#attendees [data-index="' + message.sender + '"]').addClass("badge");

                    $('.floatbadge.general-unread').remove();

                    let exists = $('.floatbadge[data-room="' + message.room_id + '"][data-uid="'+message.sender+'"]');

                    if (exists.length == 0) {
                        let floatbadge = $("<div/>").addClass("floatbadge border-primary").attr("data-room", message.room_id).attr("data-uid", message.sender);
                        floatbadge.html(Language.getItem("new-message") + ": " + user.name[localState.lang]);
                        floatbadge.on("click", function () {
                            let room = $(this).attr("data-room");
                            let uid = $(this).attr("data-uid");
                            showChat(room, uid);
                            floatbadge.remove();
                        });
                        floatbadge.appendTo($("body"));
                    }

                } else {
                    $('#chat [data-roomid="' + message.room_id + '"]').removeClass("badge");
                    $('#attendees [data-index="' + message.sender + '"]').removeClass("badge");
                }

                if ($("#chat #chatList .item.badge").length == 0) {
                    $('#landing [data-target="chat"]').removeClass("badge");
                }
            }

            $new_item.addClass("sender");

            var room = AppData.chatrooms.find(function(r) {
                return r.id == message.room_id;
            });

            if (user && prevSender != user.name[localState.lang] && room.users.length > 2) {
                $new_item.prepend('<div class="sender_username">'+user.name[localState.lang]+'</div>');
            }

            $('#chat-details .messages[data-roomid="'+message.room_id+'"]').append($new_item);

            prevSender = user.name[localState.lang];
            //$('#chat-details .messages').append($new_item);
        } else {
            $('#chat-details .messages').append($new_item);
            $new_item.find(".msg").addClass("bg-primary color-accent");

            prevSender = "self";

            if (!message.self) {
                for (var c = 0; c < AppData.chatrooms.length; c++) {
                    if (AppData.chatrooms[c].id == message.room_id) {
                        AppData.chatrooms[c].last_message = new Date().getTime() / 1000;
                        break;
                    }
                }
            }

            if ($('#chat-details .messages[data-roomid="'+message.room_id+'"]').is(":visible")) {
                localState.chat.lastMsgRead[message.room_id] = new Date().getTime();
            }

            sortList();
            renderList(false);
        }

        $('#chat #chatList [data-roomid="'+message.room_id+'"] .data-info').html(message.message);

        if (message.scroll) {
            scrollChat(400);
        }

        prevDate = formatted_date;
    }

    function scrollChat(time) {
        if (AppData.settings.homeScreen == "floating") {
            $("#chat-details .containerbgvideo .messages").animate({scrollTop: $("#chat-details .containerbgvideo .messages").prop("scrollHeight")}, time);
        } else {
            $("#chat-details article .messages").animate({scrollTop: $("#chat-details article .messages").prop("scrollHeight")}, time);
        }
    }

    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource() {
        $.post('api/', {
            do: 'getChatUsers',
            lang: localState.lang
        }, function (data) {
            AppData.chatusers = data.chatusers;
            AppData.chatrooms = data.chatrooms;
            sortList();
            renderList(true);

            if (localState.chat.active != "") {
                var users_in_chat = [];

                var room = AppData.chatrooms.find(function (rel) {
                    return rel.id == localState.chat.active;
                });

                if (room) {
                    for (var ru in room.users) {
                        if (room.users[ru] != AppData.profile.id) {
                            var u = AppData.chatusers.find(function (u_el) {
                                return u_el.id == room.users[ru];
                            });
                            users_in_chat.push(u.name[localState.lang]);
                        }
                    }
                    $("#chat-details header .data-label").html(users_in_chat.join(", "));

                    //renderDetails(room.id);
                }
            }
        })
    }

    /* ----- Update Avatar -------------------------------------------------------------------------------------------- */
    function updateAvatar(img) {
        $.get(img, function () {
            BuildFromSource();
        });
    }

    /* ----- Event: Input on Search ----------------------------------------------------------------------------------- */
    function evtInput(e) {
        var
            isResult = false,
            $btnClear = $('#chat .search-wrapper .btn-clear'),
            $noResult = $('#chat .no-result'),
            value = $('#chat .search-wrapper input').val(),
            search = value.split(' ').join(''),
            regex1 = new RegExp(strCanonical(search), 'i'),
            regex2 = new RegExp(strCanonical(search), 'i');

        var rooms = $("#chat #chatList div[data-roomid]");
        rooms.toggle(search == "");

        if (search.length > 2) {
            $.ajax({
                method: 'post',
                url: 'api/',
                data: {
                    do: 'searchChatusers',
                    lang: localState.lang,
                    searchtext: search
                },
                success: function (resp) {
                    $('#chat #chatList').html('');
                    $('#chat #chatUsersList').html('');

                    for (let i in resp) {
                        let el = resp[i];
                        if (el.id != AppData.profile.id) {
                            let $clone = $templateChatItem.clone();
                            let info = [];
                            if (el.job[localState.lang]) {
                                info.push(el.job[localState.lang]);
                            }
                            if (el.company[localState.lang]) {
                                info.push(el.company[localState.lang]);
                            }
                            $clone.attr('data-index', el.id);
                            $clone.find('.data-name').text(el.name[localState.lang]);
                            $clone.find('.data-info').text(info.join(' | ')).toggleClass('hidden', !info.length);
                            if (el.img) {
                                $clone.find('.data-img').attr('src', 'upload/users/' + el.img);
                            } else {
                                $clone.find('.data-img').attr('src', 'img/profile-picture-placeholder.png');
                            }
                            $clone.appendTo($('#chat #chatList'));
                        }
                    }
                }
            });
        } else {
            renderList(false);
            loadedUsers = [];
            $('#chat #chatUsersList').html('');
            localState.chat.page = 0;
            loadMore();
        }
    }

    function evtInputAdd(e) {
        var
            isResult = false,
            $btnClear = $('#chat-details .searchadd-wrapper .btn-clear'),
            $noResult = $('#chat-details .no-result'),
            value = $('#chat-details .searchadd-wrapper input').val(),
            search = value.split(' ').join(''),
            regex1 = new RegExp(strCanonical(search), 'i'),
            regex2 = new RegExp(strCanonical(search), 'i');

        var room_id = $("#chat-details .add_user").attr("data-roomid");
        var room = AppData.chatrooms.find(function (rel) {
            return rel.id == room_id;
        });

        if (search.length > 2) {
            $.ajax({
                method: 'post',
                url: 'api/',
                data: {
                    do: 'searchChatusers',
                    lang: localState.lang,
                    searchtext: search
                },
                success: function (resp) {
                    $('#chat-details #chatAddList').html('');

                    for (let i in resp) {
                        let el = resp[i];
                        if (el.id != AppData.profile.id && room.users.indexOf(el.id) == -1) {
                            let $clone = $templateChatItem.clone();
                            let info = [];
                            if (el.job[localState.lang]) {
                                info.push(el.job[localState.lang]);
                            }
                            if (el.company[localState.lang]) {
                                info.push(el.company[localState.lang]);
                            }
                            $clone.attr('data-index', el.id);
                            $clone.attr('data-roomid', room_id);
                            $clone.find('.data-name').text(el.name[localState.lang]);
                            $clone.find('.data-info').text(info.join(' | ')).toggleClass('hidden', !info.length);
                            if (el.img) {
                                $clone.find('.data-img').attr('src', 'upload/users/' + el.img);
                            } else {
                                $clone.find('.data-img').attr('src', 'img/profile-picture-placeholder.png');
                            }
                            $clone.appendTo($('#chat-details #chatAddList'));
                        }
                    }
                }
            });
        } else {
            $('#chat-details #chatAddList').html('');
        }
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        var room_id = $(this).attr("data-roomid") || "new_room";
        var uid = $(this).attr("data-index");
        showChat(room_id, uid);
    }

    function evtClickAdd(e) {
        e.preventDefault();

        var room_id = $(this).attr("data-roomid");
        var uid = $(this).attr("data-index");

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: "addUserToRoom",
                id: room_id,
                users: [uid],
                lang: localState.lang
            },
            success: function (resp) {
                showChat(room_id, uid);

                AppData.chatrooms = resp.rooms;
                AppData.chatusers = resp.chatusers;

                var room = AppData.chatrooms.find(function(r) {
                    return r.id == room_id;
                });
                if (room) {

                    appendMessage(resp.msg);
                    resp.msg.scroll = true;

                    var users_in_chat = [];
                    for (var u in room.users) {
                        Socket.Send("Chat.BuildFromSource", null, {
                            uid: room.users[u]
                        });

                        Socket.Send("Chat.appendMessage", resp.msg, {
                            uid: room.users[u]
                        });

                        if (room.users[u] != AppData.profile.id) {
                            var udata = AppData.chatusers.find(function (u_el) {
                                return u_el.id == room.users[u];
                            });

                            users_in_chat.push(udata.name[localState.lang]);
                        }
                    }

                    $('#chat-details .header-secondary .data-label').html(users_in_chat.join(", "));
                }

                $(".add_user_to_room").fadeOut(400);
            }
        });
    }

    function showChat(room_id, uid) {

        if (AppData.settings.homeScreen == "floating") {
            $("#chat-details .sendmessage").addClass("floating");
            $("#chat-details .container").removeClass("classic");
        } else {
            $("#chat-details .sendmessage").removeClass("floating");
            $("#chat-details .container").addClass("classic");
        }

        if (room_id == "new_room") {
            $.ajax({
                url: 'api/',
                method: 'post',
                data: {
                    do: "createChatRoom",
                    users: [
                        AppData.profile.id,
                        uid
                    ],
                    lang: localState.lang
                },
                success: function (resp) {
                    AppData.chatrooms = resp.chatrooms;
                    AppData.chatusers = resp.chatusers;

                    $("#chat-details .sendmessage .add_user").attr("data-roomid", resp.room.id);

                    Socket.Send("Chat.BuildFromSource", null, {
                        uid: uid
                    });

                    $('#chat .search-wrapper input').val('').trigger('input');

                    localState.chat.lastMsgRead[resp.room.id] = new Date().getTime();
                    localState.chat.active = resp.room.id;

                    writeDbLog("open chat", String(resp.room.id), "user_logs", "chat");

                    renderDetails(resp.room.id, uid);
                    removeBadges(resp.room_id, uid);

                    Layout.show('chat-details');
                }
            });
        } else {
            $("#chat-details .sendmessage .add_user").attr("data-roomid", room_id);

            $('.floatbadge[data-room="'+room_id+'"]').remove();

            localState.chat.lastMsgRead[room_id] = new Date().getTime();
            localState.chat.active = room_id;

            renderDetails(room_id, uid);
            removeBadges(room_id, uid);

            Layout.show('chat-details');
        }
    }

    function removeBadges(room_id, uid) {
        $('#chat #chatList .item[data-roomid="' + room_id + '"]').removeClass("badge");
        $('#attendees [data-index="' + uid + '"]').removeClass("badge");

        if ($('#chat .item.badge').length === 0) {
            $('#landing [data-target="chat"]').removeClass("badge");
        }
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClear(e) {
        e.preventDefault();
        var $input = $('#chat .search-wrapper input');
        $input.val('').trigger('input');
        renderList(false);
    }

    /* ----- Event: Press Enter --------------------------------------------------------------------------------------- */
    function evtPressEnter(e) {
        if (e.wich || e.keyCode === 13) {
            this.blur();
        }
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClearAdd(e) {
        e.preventDefault();
        var $input = $('#chat-details .searchadd-wrapper input');
        $input.val('').trigger('input');
    }

    function showFloatingChat(uid) {
        $.ajax({
            url: 'api/',
            method: 'post',
            data: {
                do: "createChatRoom",
                users: [
                    AppData.profile.id,
                    uid
                ],
                lang: localState.lang
            },
            success: function (resp) {
                AppData.chatrooms = resp.chatrooms;
                AppData.chatusers = resp.chatusers;

                $("#chat-details .sendmessage .add_user").attr("data-roomid", resp.room.id);

                Socket.Send("Chat.BuildFromSource", null, {
                    uid: uid
                });

                renderDetails(resp.room.id, uid);
                let wrapper = $("<div/>").addClass("floating_chat_wrapper");
                $("#chat-details").appendTo(wrapper);
                wrapper.appendTo("body");
                $("#chat-details header").hide();
                $("#chat-details article .closebutton").hide();
                $("#chat-details .add_user").hide();
                $("#chat-details .sendmessage").addClass("floating_chat");

                let closebutton = $("<div/>").addClass("closebutton_chat color-primary btn");
                closebutton.html(Language.getItem("chat-close"));
                closebutton.on("click", hideFloatingChat);
                $('#chat-details article .container').prepend(closebutton);

                var user = AppData.chatusers.find(function (el) {
                    return el.id == uid;
                });

                let name = $("<div/>").addClass("name_chat");
                name.html(user.name[localState.lang]);
                $('#chat-details article .container').prepend(name);

                $("#chat-details").show();
            }
        });
    }

    function UpdateModule(data) {
        let has_updated = false;

        $.each(loadedUsers, function (i, el) {
            if (el[data.id_field] == data.id_value) {
                loadedUsers[i] = data.updated_content;
                has_updated = true;
            }
        });

        $.each(AppData.chatusers, function (i, el) {
            if (el[data.id_field] == data.id_value) {
                AppData.chatusers[i] = data.updated_content;
                has_updated = true;
            }
        });

        if (has_updated) {
            showMore();
            renderList();
        }
    }

    function removeUser(data) {
        let has_updated = false;

        let i = loadedUsers.length;
        let j = AppData.chatusers.length;

        while (i--) {
            if (loadedUsers[i][data.id_field] == data.id_value) {
                loadedUsers.splice(i, 1);
                has_updated = true;
            }
        }

        while (j--) {
            if (AppData.chatusers[j][data.id_field] == data.id_value) {
                AppData.chatusers.splice(j, 1);
                has_updated = true;
            }
        }

        if (has_updated) {
            showMore();
            renderList();
        }
    }

    function hideFloatingChat() {
        $("#chat-details header").show();
        $("#chat-details article .closebutton").show();
        $("#chat-details .add_user").show();
        $("#chat-details .sendmessage").removeClass("floating_chat");
        $("#chat-details article .closebutton_chat").remove();
        $("#chat-details article .name_chat").remove();
        $("#chat-details").appendTo("body");
        Layout.hide("chat-details", true);
        $(".floating_chat_wrapper").remove();
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        evtClickDetails: evtClick,
        refresh: refresh,
        showChat: showChat,
        appendMessage: appendMessage,
        BuildFromSource: BuildFromSource,
        updateAvatar: updateAvatar,
        showFloatingChat: showFloatingChat,
        hideFloatingChat: hideFloatingChat,
        UpdateModule: UpdateModule,
        removeUser: removeUser
    };
})();
