var Settings = (function () {
    'use strict';

    /* ----- Init Settings -------------------------------------------------------------------------------------------- */
    function Init() {
        if (AppData.profile == undefined && location.href.indexOf("login") == -1) {
            location.href = location.href.replace('/client.html', '/login.html');
            return false;
        }

        var
            data = AppData.settings || [],
            inlineStyle = '',
            $layouts = $('div.main');
        /* Set Background Image */
        if ('bgImage' in data) {

            let bg = AppData.medias.find(function (item){
                return item.id == data.bgImage;
            });
            if (bg && bg.files[localState.lang] != undefined) {
                $layouts
                    .filter('#landing').find('header')
                    .css('backgroundImage', 'url("' + bg.files[localState.lang].file + '")');
            }
        }
        /* Set Foreground Image */

        $layouts.filter('#landing').find('.hero').hide();

        if (AppData.settings.foregroundtype == "image") {
            if ('fgImage' in data) {

                let fg = AppData.medias.find(function (item){
                    return item.id == data.fgImage;
                });
                if (fg && fg.files[localState.lang].file != undefined) {
                    $layouts
                        .filter('#landing').find('.hero')
                        .html("")
                        .css('backgroundImage', 'url("' + fg.files[localState.lang].file + '")').show();
                }
            }
        }

        if (AppData.settings.foregroundtype == "text") {
            if (AppData.modulsettings.fgtext) {
                $layouts
                    .filter('#landing').find('.hero')
                    .css('backgroundImage', 'none')
                    .html(AppData.modulsettings.fgtext[localState.lang]).show();
            }
        }
        /* Set Logo Image */
        if ('logo' in data) {

            let logo = AppData.medias.find(function (item){
                return item.id == data.logo;
            });
            if (logo && logo.files[localState.lang] != undefined) {
                $layouts.filter('#landing').find('header .logo').attr('src', logo.files[localState.lang].file);
                $layouts.find('.header-primary .logo').attr('src', logo.files[localState.lang].file);
                $("#unity3d-details .loader .loader_logo").attr("src", logo.files[localState.lang].file);
            }
        }
        /* Set Start Page */
        fadeInLanding(data.startPage);

        if (data.startPage === "game") {
            $(".open-main.hamburger").hide();
        } else {
            $(".open-main.hamburger").show();
        }

        /* Set Logo Alignment */
        if ('logoPosition' in data) {
            $layouts.filter('#landing header').find(' .container').not(".navplusbutton").css('justify-content', data.logoPosition);
            $layouts.filter('#landing').find('header .container .navplusbutton').css('justify-content',"flex-end");
        }

        /* Set UserName */
        if ('user' in data) {
            data.user = AppData.profile.name[localState.lang] || data.user;
            $layouts.filter('#welcome').find('article .data-user').text(data.user);
            $layouts.filter('#hamburger').find('.data-user').text(data.user);
        }
        /* Set Profile Image */
        $layouts.filter('#hamburger').find('.data-img').attr('src',
            AppData.profile.img || false ? 'upload/users/' + AppData.profile.img + '?r=' + Math.random() : 'img/profile-picture-placeholder.png'
        );
        /* Set User Info: Name/IP */
        if ('userInfo' in data) {
            if (data.userInfo === 'ip') {
                $layouts.find('.header-primary .data-user').html(AppData.server.client_addr || 'NOIP');
            } else {
                $layouts.find('.header-primary .data-user').text(data.user);
            }
        }
        /* Set Show Logout */
        if ('showLogout' in data) {
            $layouts.find('.logout').toggleClass('hidden', String(data.showLogout) === 'false');
        }
        if ('showHome' in data) {
            $layouts.find('.home').toggleClass('hidden', String(data.showHome) === 'false');
        }
        if (AppData.profile) {
            $('div#game').find('header .data-subtitle').text(AppData.profile.name[localState.lang] || 'Unknown');
        }
        /* Set Software Version Visibility  */
        if ('showVersion' in data) {
            $('#software').toggle(String(data.showVersion) === 'true');
            if (String(data.showVersion) === 'true') {
                if (AppData.settings.homeScreen == "floating") {
                    $("#default_nav").addClass("elevated");
                }
            } else {
                if (AppData.settings.homeScreen == "floating") {
                    $("#default_nav").removeClass("elevated");
                }
            }
        }
        /* Set Text Direction */
        if ('textDirection' in data) {
            $(document.documentElement).attr('dir', data.textDirection);
        }
        /* Set Header Reverse */
        if ('headerReverse' in data) {
            $layouts.find('.header-primary').toggleClass('reverse', String(data.headerReverse) === 'true');
            $("div#hamburger").toggleClass('reverse', String(data.headerReverse) === 'true');
        }
        /* Set Change Language Visibility */
        if ('changeLang' in data) {
            $layouts.find('.changeLang')
                .closest('fieldset')
                .toggleClass('hidden', String(data.changeLang) === 'false');
        }
        /* Set Primary Color */
        if ('colorPrimary' in data) {
            inlineStyle += '.color-primary { color: ' + data.colorPrimary + ' !important; }';
            inlineStyle += '.bg-primary { background-color: ' + data.colorPrimary + ' !important; }';
            inlineStyle += '.border-primary { border-color: ' + data.colorPrimary + ' !important; }';
            inlineStyle += "@keyframes blinkingiconanimation3 {50% {color: #ffffff; background-color: " + data.colorPrimary + "}}";
            $layouts.find( '.header-secondary' ).css( 'backgroundColor', data.colorPrimary );
            /*$layouts.find( '.header-secondary' ).css( 'backgroundColor', data.colorPrimary );
             $layouts.find( 'nav .data-icon' ).css( 'color', data.colorPrimary );
             $layouts.find( '.scroll-top' ).css( 'backgroundColor', data.colorPrimary );*/
        }
        /* Set Secondary Color */
        if ('colorSecondary' in data) {
            inlineStyle += '.color-accent { color: ' + data.colorSecondary + ' !important; }';
            inlineStyle += '.bg-accent { background-color: ' + data.colorSecondary + ' !important; }';
            inlineStyle += '.border-accent { border-color: ' + data.colorSecondary + ' !important; }';
            /*$layouts.find( '.header-primary .data-title' ).css( 'color', data.colorSecondary );
             $layouts.find( '.header-primary .data-subtitle' ).css( 'color', data.colorSecondary );
             $layouts.find( 'nav .data-title' ).css( 'color', data.colorSecondary );
             $( document.body ).css( 'backgroundColor', data.colorSecondary );*/
        }

        if ('colorIconBackground' in data) {
            inlineStyle += '.color-primary { background-color: ' + data.colorIconBackground + ' !important; }';
        }

        if ('colorIconText' in data) {
            inlineStyle += '.color-icon-text { color: ' + data.colorIconText + ' !important; }';

        }

        if ('iconTextShadow' in data) {
            if (data.iconTextShadow) {

                let color = "#000";
                if (data.colorIconTextShadow != undefined) {
                    color = data.colorIconTextShadow;
                }

                inlineStyle += '#default_nav .wrapper .data-title { text-shadow: 1px 1px 3px' + color + ' !important; }';

                /*$("#default_nav .wrapper .data-title").css({
                    "text-shadow": "1px 1px 3px "+color
                });*/
            } else {

                inlineStyle += '#default_nav .wrapper .data-title { text-shadow: none !important; }';

                /*$("#default_nav .wrapper .data-title").css({
                    "text-shadow": "none"
                });*/
            }
        }

        /* Show/hide lot of bubbles */
        if ('bubble' in data) {
            // $layouts.find('#showBubble1').toggle();
            // $layouts.find('#showBubble2').toggle();
            if (data.bubble == "hidden") {
                $layouts.find('#showBubble1').hide();
                $layouts.find('#showBubble2').hide();
            } else {
                $layouts.find('#showBubble1').show();
                $layouts.find('#showBubble2').show();
                if (data.bubble == "reverse") {
                    $layouts.find('#showBubble1').addClass("reverse");
                    $layouts.find('#showBubble2').addClass("reverse");
                } else {
                    $layouts.find('#showBubble1').removeClass("reverse");
                    $layouts.find('#showBubble2').removeClass("reverse");
                }
            }
        }

        /* Set Title*/
        $layouts.find('.header-primary .data-title').text(AppData.modulsettings.title[localState.lang]);

        /* Set SubTitle */
        $layouts.find('.header-primary .data-subtitle').text(AppData.modulsettings.subTitle[localState.lang]);

        /* Set Inline Styles */
        if (inlineStyle.length) {
            $('head').append('<style>' + inlineStyle + '</style>');
        }

        switch(AppData.settings.homeScreen) {
            case "classic":
                //$("#navplus").hide();
                $("nav#default_nav").removeClass("floating panel");
                $("nav#default_nav .wrapper").removeClass("animated fadeIn fadeOut").show();
                $(".navplusbutton").addClass("hidden");
                $("nav#default_nav .closemenu").addClass("hidden");

                $("article").removeClass("articlebgvideo");
                $("article .container").removeClass("containerbgvideo");

                $("#default_nav").css("background-color", AppData.settings.colorMenuBackground);

                $("div.main").not("#hamburger").not("#modal").css("background-color", "");

                // $("#default_nav").appendTo($("#landing"));
                break;
            case "floating":
                //$("#navplus").show();
                $("nav#default_nav").addClass("floating");
                if (AppData.settings.menupanel) {
                    $("nav#default_nav").addClass("panel").removeClass("not-panel");
                    //$(".navplusbutton").addClass("fixed").appendTo("#landing");
                } else {
                    $("nav#default_nav").addClass("not-panel").removeClass("panel");
                    //$(".navplusbutton").removeClass("fixed").appendTo("#default_nav .container");
                }
                $("#landing .wrapper").not(".navplusbutton").hide();
                $(".navplusbutton").removeClass("hidden");
                $("nav#default_nav .closemenu").removeClass("hidden");
                //$(".navplusbutton i").removeClass("fa-minus").addClass("fa-plus");
                $("#default_nav").removeClass("opened blur");

                $("article").addClass("articlebgvideo");
                $("article .container").addClass("containerbgvideo");

                $("#default_nav").css("background-color", "");

                $("div.main").not("#hamburger").not("#modal").not(".system").css("background-color", "transparent");
                $("div#landing .landing_elements").find(".trueconf").css("z-index", "1000");

                $("#default_nav").appendTo($(".bodycontent"));
                break;
        }

        $('nav#default_nav').show();

        var rgbcolor = hexToRgb(AppData.settings.colorMenuBackground);

        let menuopacity = AppData.settings.menuOpacity || 50;

        // console.log("menuopacity", menuopacity, (menuopacity / 100));

        if ($("#navplus i").hasClass("fa-minus")) {
            $("#navplus").find(".container").css("background-color", 'rgba(' + rgbcolor.r + ',' + rgbcolor.g + ',' + rgbcolor.b + ', '+(menuopacity / 100)+')');
        }
        if ($("#landing .landing_elements .moderate_cont").is(":visible"))
            $("#navplus").find("[data-target='moderate']").css("border", '2px solid ' + AppData.settings.colorIconBorder);
        if ($("#landing .landing_elements .stage_cont").is(":visible"))
            $("#navplus").find("[data-target='stage']").css("border", '2px solid ' + AppData.settings.colorIconBorder);

        let headerBg = hexToRgb("#FFFFFF");

        if ('colorHeaderBackground' in data) {
            headerBg = hexToRgb(AppData.settings.colorHeaderBackground);
        }

        let headerOpacity = 1;
        if ('headerOpacity' in data) {
            headerOpacity = AppData.settings.headerOpacity / 100;
        }

        $("div.main > header > section").css("background-color", 'rgba(' + headerBg.r + ',' + headerBg.g + ',' + headerBg.b + ', '+headerOpacity+')');

        if (headerOpacity == 0) {
            $("div.main > header").css("background-position-y", $("div.main > header > section").height()+"px");
        } else {
            $("div.main > header").css("background-position-y", "");
        }

        if ('colorHeaderText' in data) {
            $("div.main > header > .header-primary").css("color", AppData.settings.colorHeaderText);
        }

        Namecards.Init();

        return true;
    }

    /* ----- FadeIn Landing Layout ------------------------------------------------------------------------------------ */
    function fadeInLanding(layout) {
        var startLayout = layout || 'landing';

        if($.inArray("profile", localState.layout) === -1) {
            $('div.main').not("#landing").hide();
        }

        $.each(localState.layout, function (i, el) {
            Layout.show(el, true);
        });

        $('div#landing').show();

        $("div.main header .close-main").show();
        $("div.main header .hamburger").parent().show();
        $("div.main header .header-primary").removeClass("disable-pe");

        if (startLayout.indexOf("#") > -1) {
            var splitted = startLayout.split("#");
            var selector = splitted[0];
            selector = selector.charAt(0).toUpperCase() + selector.slice(1);
            window[selector].showDetails(splitted[1], false);
            startLayout = splitted[0]+"-details";
        }

        //$('main#' + startLayout).addClass('animated fadeIn fast');

        if (startLayout != "landing") {
            $('div#' + startLayout).show();
            if (AppData.settings.allow_home_navigation) {
                $("div#" + startLayout + " header .close-main").show();
                $("div#" + startLayout + " header .hamburger").parent().show();
                $("div#" + startLayout + " header .header-primary").removeClass("disable-pe");
            } else {
                $("div#" + startLayout + " header .close-main").hide();
                $("div.main header .hamburger").parent().hide();
                $("div#landing header .hamburger").parent().show();
                $("div.main header .header-primary").addClass("disable-pe");
                $("div#landing header .header-primary").removeClass("disable-pe");
            }
        }

        if (typeof Surveys.Init == "function") {
            Surveys.Init();
        }

        if (AppData.profile.game === false && (AppData.settings.start_survey != "" && AppData.settings.start_survey != undefined)) {

            let filled = AppData.surveysdata.find(function (el) {
                return el.survey == AppData.settings.start_survey;
            });

            if (!filled) {
                Surveys.showDetails(AppData.settings.start_survey);
            }
        }

        if (AppData.settings.start_survey == "" || AppData.settings.start_survey == undefined) {
            Layout.hide("surveys-details");
        }
    }

    function BuildFromSource() {
        $.post('api/', {
            do: 'getSettings'
        }, function (data) {
            AppData.settings = data;
            document.title = AppData.modulsettings.browsertitle[localState.lang];
            setFavicon();
            if(AppData.settings.blink_menu_icon) {
                $(".navplusbutton").children().addClass("blink_button").removeClass("color-primary");
            } else {
                $(".navplusbutton").children().removeClass("blink_button").addClass("color-primary");
            }
            Init();
            var streamplayer = videojs('my-video-bg');
            if (AppData.settings.showHomeScreenVideo) {
                setHomeScreenVideo(streamplayer);
            } else {
                streamplayer.paused(true);
                $("#playerElementBackground").hide();
            }
            setHomeScreenVideo();
            data = null;
            Layout.BuildFromSource();
        })
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        BuildFromSource: BuildFromSource
    };
})();
