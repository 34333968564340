/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Attendees = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var $templateAttendeesItem,
        pageCount = 20;
    localState.attendees = localState.attendees || {};
    localState.attendees.itemIndex = localState.attendees.itemIndex || 0;
    localState.attendees.page = 0;

    /* ----- Init Attendees -------------------------------------------------------------------------------------------- */
    function Init() {
        $templateAttendeesItem = $('template#tplAttendeesItem').remove().contents();
        $(document).on('input', '#attendees .search-wrapper input', evtInput);
        $(document).on('keyup', '#attendees .search-wrapper input', evtPressEnter);
        $(document).on('click', '#attendees .search-wrapper .btn-clear', evtClear);
        $(document).on('click', '#attendees #attendeesList .item', evtClick);
        $(document).on('click', '#attendees #attendeesList .load-more', loadMore);
        $(document).on('click', '#attendees-details .attendees-card-request', evtClickBusinessCard);
        $(document).on('click', '#attendees-details .start_chat', startChat);
        $(document).on('click', '#attendees-details .favorite.save', evtClickFavorite);
        $(document).on('click', '#attendees-details .favorite.cancel', evtClickCancelFavorite);
        $(document).on('click', '#attendees-details .external-link', openExternalLink);
        if ('search' in localState.attendees && localState.attendees.search !== "") {
            $('#attendees .search-wrapper input').val(localState.attendees.search).trigger('input');
        } else  {
            loadMore();
        }
    }

    function sort() {
        AppData.user_fields.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });
    }

    function addUserFields(data) {
        sort();
        var user_fields = AppData.user_fields.filter(function(el) {
                return el.is_active === true;
            }),
            div,
            icon,
            section;

        $("#attendees-details .custom_fields").html("");
        $.each(user_fields, function(i,el) {
            section = $("<section/>");
            section.addClass("item " + (el.field_type === "text" ? "text-center" : "text-justify"));
            div = $("<div/>");
            section.append(div);

            icon = "";
            if(el.field_icon !== "") {
                icon = "<i class='" + el.field_icon + "'></i> ";
            }
            if(el.field_translated) {
                if(data && data.values[el.field_name].title !== '') {
                    div.html(icon + data.values[el.field_name].title[localState.lang]);
                    $("#attendees-details .custom_fields").append(section);
                }
            } else {
                if(data && data.values[el.field_name].title !== '') {
                    div.html(icon + data.values[el.field_name].title);
                    $("#attendees-details .custom_fields").append(section);
                }
            }
            // if(data && data.values[el.field_name].title !== undefined) {
            //     div.html(el.translated ? data.values[el.field_name].title[localState.lang] : data.values[el.field_name].title);
            // }
        });
    }

    function openExternalLink() {
        var link = $(this).attr("data-link");
        window.open(link);
    }

    /* ----- Sort List ------------------------------------------------------------------------------------------------ */
    function sortList() {
        AppData.attendees.sort(function (a, b) {
            return String(a.name[localState.lang]).localeCompare(String(b.name[localState.lang]));
        });
    }

    /* ----- Build Attendees List -------------------------------------------------------------------------------------- */
    function renderList() {
        var
            $self = $('#attendees #attendeesList'),
            $fragmentAttendees = $(document.createDocumentFragment());
        /* Set Agenda List */
        $.each(AppData.attendees, function (i, el) {
            if (el.active) {
                var
                    $clone = $templateAttendeesItem.clone(),
                    info = [];
                $.each(el.values, function(i,e) {
                    if (e.showrow === '1' && e.is_active) {
                        info.unshift(typeof e.title == "object" ? e.title[localState.lang] : e.title);
                    } else if (e.showrow === '2' && e.is_active) {
                        info.push(typeof e.title == "object" ? e.title[localState.lang] : e.title);
                    }
                });

                $clone.attr('data-index', el.id);
                $clone.find('.data-name').text(el.name[localState.lang]);
                $clone.find('.data-info').html(info.join('<br>')).toggleClass('hidden', !info.length || !AppData.modulsettings.attendeeshowinfo);
                if (el.img || false) {
                    $clone.find('.data-img').attr(($self.is(':visible')) ? 'src' : 'data-src', 'upload/users/' + el.img);
                } else {
                    $clone.find('.data-img').attr('src', 'img/profile-picture-placeholder.png');
                }
                $clone.appendTo($fragmentAttendees);
            }
        });

        let morecont = $("<div/>").addClass("more-cont");
        let morebtn = $("<button/>").addClass("btn load-more").html(Language.getItem("load-more"));

        morebtn.appendTo(morecont);
        morecont.appendTo($fragmentAttendees);

        $self.html($fragmentAttendees);
    }

    function loadMore() {
        $("#attendees #attendeesList .load-more").addClass("disabled").prop("disabled", true).show();

        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: "loadMoreAttendees",
                page: localState.attendees.page,
                lang: localState.lang
            },
            success: function (response) {
                $("#attendees #attendeesList .load-more").removeClass("disabled").prop("disabled", false);
                localState.attendees.page++;
                AppData.attendees = AppData.attendees.concat(response['attendees']);
                AppData.user_fields = response.user_fields;

                UpdateModule();

                if(response.count === 0 ||  response.count <= (localState.attendees.page * pageCount) ) {
                    $("#attendees #attendeesList .load-more").hide();
                } else {
                    $("#attendees #attendeesList .load-more").show();
                }
                $('#attendees .no-result').toggle(response.count === 0);
            }, error: function () {
                $("#attendees #attendeesList .load-more").removeClass("disabled").prop("disabled", false);
            }
        });
    }

    function toggleRequestBusinessCard() {
        if(AppData.modulsettings.showexchangeprofileattendees) {
            $("#attendees-details").find(".favorite").show();
        } else {
            $("#attendees-details").find(".favorite").hide();
        }
    }

    function toggleSocial() {
        if(AppData.modulsettings.showsociallinks) {
            $("#attendees-details").find(".social").show();
        } else {
            $("#attendees-details").find(".social").hide();
        }
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails() {
        if ('attendees' in localState) {
            var
                $attendee = $('#attendees-details article'),
                $header = $('#attendees-details .data-label'),
                state = localState.attendees,
                item = AppData.favorites.find(function (item) {
                    return item.type === 'attendee' && item.data === localState.attendees.itemIndex
                }),
                saved = Boolean(item),
                undo = Boolean(item) && !item.sent,
                el = AppData.attendees.find(function (el) {
                    return el.id === localState.attendees.itemIndex
                });

            if (!el) {
                return false;
            }
            $header.text(el.name[localState.lang]);

            $attendee.find('.data-img').attr({
                'src': 'img/profile-picture-placeholder.png',
                'data-src': null
            });
            if (el.img || false) {
                $attendee.find('.data-img').attr('src', 'upload/users/' + el.img);
            }
            var
                phone  = el.phone ? atob(atob(el.phone).substring(1)) : '',
                email  = el.email ? atob(atob(el.email).substring(1)) : '';

            let fb = el.facebook_url ? el.facebook_url : '';
            let tw = el.twitter_url ? el.twitter_url : '';
            let ins = el.instagram_url ? el.instagram_url : '';
            let li = el.linkedin_url ? el.linkedin_url : '';

            $attendee.find('.facebook').attr("data-link", fb).toggleClass('hidden', !fb);
            $attendee.find('.twitter').attr("data-link", tw).toggleClass('hidden', !tw);
            $attendee.find('.instagram').attr("data-link", ins).toggleClass('hidden', !ins);
            $attendee.find('.linkedin').attr("data-link", li).toggleClass('hidden', !li);

            $attendee.find('.social').toggleClass('hidden', !fb && !tw && !ins && !li);

            $attendee.find('.data-name').text(el.name[localState.lang]);
            $attendee.find('.data-job').text(el.job[localState.lang]).toggleClass('hidden', !el.job[localState.lang]);
            $attendee.find('.data-company').text(el.company[localState.lang]).toggleClass('hidden', !el.company[localState.lang]);

            $attendee.find('.data-phone').text(phone).parent().toggleClass('hidden', !phone);
            $attendee.find('.data-email').text(email).parent().toggleClass('hidden', !email);

            $attendee.find('.data-slogan').text(nl2br(el.slogan[localState.lang])).parent().toggleClass('hidden', !el.slogan[localState.lang]);
            $attendee.find('.data-department').text(nl2br(el.department[localState.lang])).parent().toggleClass('hidden', !el.department[localState.lang]);
            $attendee.find('.data-university').text(nl2br(el.university[localState.lang])).parent().toggleClass('hidden', !el.university[localState.lang]);
            $attendee.find('.data-skill').text(nl2br(el.skill[localState.lang])).parent().toggleClass('hidden', !el.skill[localState.lang]);
            $attendee.find('.data-bio').html(nl2br(el.bio[localState.lang])).toggleClass('hidden', !el.bio[localState.lang]);

            $attendee.find('.listing').toggleClass('hidden', !phone && !email);
            $attendee.find('.social').toggleClass('hidden', !fb && !tw && !ins && !li);

            // $attendee.find('#attendees-card-request').attr('data-id', el.id);
            $attendee.find('.favorite.save').toggleClass('hidden', saved);
            $attendee.find('.favorite.saved').toggleClass('hidden', !saved);
            $attendee.find('.favorite.cancel').toggleClass('hidden', !undo);

            phone = email = null;

            var chat_allowed = AppData.navigation.find(function (item) {
                return item.module == "chat" && item.visible && AppData.profile.id != el.id;
            });

            if (chat_allowed) {
                $(".start_chat").parent().show();
            } else {
                $(".start_chat").parent().hide();
            }

            addUserFields(el);
            toggleRequestBusinessCard();
            toggleSocial();
        }
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClickFavorite() {
        var $self = $('#attendees-details');
        $self.find('.favorite.save').addClass('hidden');
        $self.find('.favorite.saved').removeClass('hidden');
        $self.find('.favorite.cancel').removeClass('hidden');
        $.post('api/', {
            do: 'saveFavoriteAttendee',
            data: {'id' : localState.attendees.itemIndex || false, 'lang': localState.lang}
        }, function (res) {
            AppData.favorites = res;
            Modal.resultconfirmation(
                {"canClose": true,
                    "title": AppData.languages[localState.lang].businesscardrequest_title,
                    "text":  AppData.languages[localState.lang].businesscardrequest_text
                }
            );
            res = null;
        });
        $self = null;
    }

    function evtClickCancelFavorite() {
        var
            $self = $('#attendees-details'),
            saved = AppData.favorites.find(function (item) {
                return item.type === 'attendee' && item.data === localState.attendees.itemIndex
            });
        $self.find('.favorite.save').removeClass('hidden');
        $self.find('.favorite.saved').addClass('hidden');
        $self.find('.favorite.cancel').addClass('hidden');
        $.post('api/', {
            do: 'removeFavorite',
            data: saved.id
        }, function (res) {
            AppData.favorites = res;
            res = null;
        });
        $self = null;
    }

    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource() {
        AppData.attendees = [];
        localState.attendees.page = 0;
        loadMore();
    }

    function UpdateModule() {
        sortList();
        renderList();
        renderDetails();
    }

    /* ----- Update Avatar -------------------------------------------------------------------------------------------- */
    function updateAvatar(img) {
        $("#attendees [data-index='"+img.id+"'] .data-img").attr('src', img.img+"?r="+Math.random());
        let userindex = AppData.attendees.findIndex(function (item) {
            return item.id == img.id;
        });

        if (userindex > -1) {
            let imgname = img.img.split("/");
            AppData.attendees[userindex].img = imgname[imgname.length - 1];
        }
    }

    function updateUser(data) {
        let userindex = AppData.attendees.findIndex(function (item) {
            return item.id == data.id;
        });

        if (userindex > -1 && data.udata.length > 0) {
            AppData.attendees[userindex] = data.udata[0];
            UpdateModule();
        }
    }

    /* ----- Event: Input on Search ----------------------------------------------------------------------------------- */
    function evtInput(e) {
        var
            isResult = false,
            $btnClear = $('#attendees .search-wrapper .btn-clear'),
            $noResult = $('#attendees .no-result'),
            value = $('#attendees .search-wrapper input').val(),
            search = value.split(' ').join(''),
            regex1 = new RegExp(strCanonical(search), 'i'),
            regex2 = new RegExp(strCanonical(search), 'i');
        localState.attendees.search = value;
        requestAnimationFrame(function () {
            if(search.length > 1) {
                $.ajax({
                    method: 'post',
                    url: 'api/',
                    data: {
                        do: "searchAttendees",
                        search: value.split(' '),
                        lang: localState.lang
                    },
                    success: function (response) {
                        AppData.attendees = response;
                        UpdateModule();
                        $btnClear.toggle(Boolean(value.length));
                        $noResult.toggle(!Boolean(response.length));
                        $("#attendees #attendeesList .load-more").hide();
                        isResult = $btnClear = $noResult = value = search = regex1 = regex2 = null;
                    }, error: function (error) {
                        console.log("error", error);
                    }
                });
            } else if(search.length === 0) {
                $btnClear.trigger('click');
            }
        });
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClick() {
        localState.attendees.itemIndex = String($(this).data('index')) || 0;
        renderDetails();
        writeDbLog("show attendee profile", localState.attendees.itemIndex, "user_logs", "users");
        Layout.show('attendees-details');
    }

    function startChat() {
        Chat.showChat("new_room", localState.attendees.itemIndex);
    }

    /* ----- Event: Click on Item ------------------------------------------------------------------------------------- */
    function evtClear(e) {
        e.preventDefault();
        var $input = $('#attendees .search-wrapper input');
        $input.val('');
        localState.attendees.search = "";
        $('#attendees .search-wrapper .btn-clear').hide();
        $.post('api/', {
            do: "loadMoreAttendees",
            multipage: "true",
            lang: localState.lang,
            page: localState.attendees.page
        }, function(response) {
            AppData.attendees = response.attendees;
            UpdateModule();
            if(response.count === 0 ||  response.count <= (localState.attendees.page * pageCount) ) {
                $("#attendees #attendeesList .load-more").hide();
            } else {
                $("#attendees #attendeesList .load-more").show();
            }
            $('#attendees .no-result').toggle(response.count === 0);        });
    }

    /* ----- Event: Press Enter --------------------------------------------------------------------------------------- */
    function evtPressEnter(e) {
        if (e.wich || e.keyCode === 13) {
            this.blur();
        }
    }

    function evtClickBusinessCard(e) {

        $.post('api/', {
            do: 'saveBusinessCard',
            data: {"id": e.target.dataset.id, "lang": localState.lang}
        }, function (res) {
            // AppData.favorites = res;
            // console.log(res);
            Modal.resultconfirmation(
                {"canClose": true,
                      "title": AppData.languages[localState.lang].businesscardrequest_title,
                      "text":  AppData.languages[localState.lang].businesscardrequest_text
                      }
            );
            res = null;
        });
        // $self = null;

    }

    function showDetails(id) {
        if (id) {
            if (localState.attendees != undefined) {
                if (id !== localState.attendees.itemIndex || localState.attendees.itemIndex === 0) {
                    localState.attendees.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.attendees = { 'itemIndex': String(id)};
            }
            Layout.show('attendees-details');
        }
    }

    /* ----- updateRequestBusinessCard ------------------------------------------------------------------------------ */
    function updateRequestBusinessCard() {
        $.post('api/', {
            do: 'getFavorites'
        }, function (data) {
            AppData.favorites = data;
            renderDetails();
            data = null;
        })
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        evtClickDetails: evtClick,
        renderList: renderList,
        showDetails: showDetails,
        BuildFromSource: BuildFromSource,
        updateAvatar: updateAvatar,
        updateUser: updateUser,
        UpdateModule: UpdateModule,
        toggleRequestBusinessCard: toggleRequestBusinessCard,
        updateRequestBusinessCard: updateRequestBusinessCard,
        toggleSocial: toggleSocial
    };
})();
