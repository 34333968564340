/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 *
 */
var Notifications = (function () {
    'use strict';

    var $templateNotificationsItem;

    function Init() {
        $templateNotificationsItem = $('template#tplNotificationsItem').remove().contents();
        renderList();
        delete Notifications.Init;
    }

    function renderList() {
        var
            $self = $('#notifications .notificationsList'),
            $fragmentNotifications = $(document.createDocumentFragment()),
            data;

        $.each(AppData.pushednotifications, function (i, el) {
            data = AppData.notifications.filter(function(e) {
                return e.id === el.notification_id;
            })[0];
            if(data !== undefined) {
                var $clone = $templateNotificationsItem.clone();
                $clone.attr('data-index', data.id);
                $clone.find('.data-title').text(data.title[localState.lang]);
                $clone.find('.data-pusheddate').text(el.pushed_date);
                $clone.appendTo($fragmentNotifications);
            }
        });
        $self.html($fragmentNotifications);
        $('#notifications').find('.no-result').toggle(!AppData.notifications.length);
    }

    function renderDetails() {
        var dataindex = this.dataset.index;
        var notification = AppData.notifications.filter(function(e) {
            return e.id === dataindex;
        });
        notification[0].canClose = true;
        Modal.notification(notification[0]);
    }

    function showDetails(id) {
        if (id) {
            if (localState.notifications != undefined) {
                if (id !== localState.notifications.itemIndex || localState.notifications.itemIndex === 0) {
                    localState.notifications.itemIndex = String(id);
                    renderDetails();
                }
            } else {
                localState.notifications = { 'itemIndex': String(id)};
            }
            Layout.show('notifications-details');
        }
    }

    function BuildFromSource() {

        $.post('api/', {
            'do': 'getPushedNotifications'
        }, function(data) {
            AppData.pushednotifications = data;
            renderList();
        });
    }

    $(document)
        .on('click', '#notifications .item', renderDetails);
    return {
        Init: Init,
        showDetails:showDetails,
        renderList:renderList,
        BuildFromSource:BuildFromSource
    }
})();
