/**
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

var Promotions = (function () {

    let promotions = {};
    let templateWeekItem;
    let templateItem;

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function Init() {
        templateWeekItem = $('template#tplPromotionsWeekItem').remove().contents();
        templateItem = $('template#tplPromotionsItem').remove().contents();
        render();
    }

    $(document).on("click", "#promotions .promotionsList .book_ad", bookPromotion);
    $(document).on("click", "#promotions .prices_cont", function () {
        Modal.alert('<img src="upload/csomag-osszefoglalo.png" style="max-width: 100%">');
    });

    let saving = false;

    function bookPromotion(e) {
        e.preventDefault();

        let _this = $(this);

        if (!saving) {

            let quantity = 1; //_this.closest('.promotionItem').find('.reserve_quantity').val();
            let comment = _this.closest('.promotionItem').find('.reserve_quantity').val();

            if (comment != "") {
            //if (parseInt(quantity) > 0 && quantity != "") {
                Modal.confirm(Language.getItem("sure-to-reserve"), function () {
                    saving = true;

                    let pid = _this.closest('.promotionItem').attr('data-id');

                    $.ajax({
                        method: 'post',
                        url: 'api/',
                        data: {
                            do: 'reservePromotion',
                            pid: pid,
                            quantity: quantity,
                            comment: comment
                        },
                        success: function (resp) {
                            saving = false;
                            Modal.alert(Language.getItem(resp.message));
                            _this.closest('.promotionItem').find('.reserve_quantity').val('');
                            if (resp.status == "ok") {
                                AppData.user_promotions = resp.user_promotions;
                                refresh({promotions: resp.promotions,
                                    data: {
                                        uid: AppData.profile.id,
                                        name: AppData.profile.name,
                                        company: AppData.profile.company,
                                        pid: pid,
                                        quantity: quantity,
                                        comment: comment
                                    }
                                });
                                Socket.Send("Promotions.refresh", {promotions: resp.promotions,
                                    data: {
                                        uid: AppData.profile.id,
                                        name: AppData.profile.name,
                                        company: AppData.profile.company,
                                        pid: pid,
                                        quantity: quantity,
                                        comment: comment
                                    }
                                });
                            }
                        }
                    });
                });
            } else {
                saving = false;
                //Modal.alert(Language.getItem("quantity-required"));
                Modal.alert("Kérjük adja meg a termék megnevezését.");
            }

        }
    }

    /* ----- Init ----------------------------------------------------------------------------------------------------- */
    function render() {
        sort();

        $("#promotions .promotionsList").html("");

        for (let week in promotions) {
            let weekClone = templateWeekItem.clone();

            let pdate = AppData.promotiondates.find(function (item) {
                return week == item.week_num;
            });

            weekClone.find(".title").html(pdate.date_text+"<br>"+week + ". " + Language.getItem("week"));

            let itemContainer = weekClone.find(".promotionItems");

            for (let item in promotions[week]) {
                let promotionItem = promotions[week][item];
                let itemClone = templateItem.clone();
                itemClone.attr("data-id", promotionItem.id);
                itemClone.find(".title .title_inner").html(promotionItem.name[localState.lang]);
                if (promotionItem.description != undefined) {
                    itemClone.find(".info_text").html(promotionItem.description[localState.lang]);
                    itemClone.find(".promo_info").removeClass("hidden");
                } else {
                    itemClone.find(".promo_info").addClass("hidden");
                }

                itemClone.find(".quantity .ad_quantity_title").html(Language.getItem("ad_quantity"));
                itemClone.find(".quantity .ad_quantity").html(promotionItem.available);

                if (parseInt(promotionItem.available) <= 0) {
                    itemClone.addClass("no_more");
                }

                let ureserved = 0;
                for (let r in AppData.user_promotions) {
                    if (AppData.user_promotions[r].promotion_id == promotionItem.id) {
                        ureserved += parseInt(AppData.user_promotions[r].quantity);
                    }
                }

                if (ureserved > 0) {
                    itemClone.addClass("has_own");
                }

                itemClone.find(".reserved .ad_reserved_title").html(Language.getItem("ad_reserved"));
                itemClone.find(".reserved .ad_reserved").html(ureserved);

                itemClone.find(".book_ad").html(Language.getItem("book_ad"));

                if (AppData.profile.group == "attendee") {
                    itemClone.find(".reserved").removeClass("hidden");
                    itemClone.find(".reserve_quantity").removeClass("hidden");
                    itemClone.find(".book_ad").removeClass("hidden");
                    itemClone.find(".infotext").removeClass("hidden");
                } else {
                    itemClone.find(".reserved").addClass("hidden");
                    itemClone.find(".reserve_quantity").addClass("hidden");
                    itemClone.find(".book_ad").addClass("hidden");
                    itemClone.find(".infotext").addClass("hidden");
                }

                itemClone.appendTo(itemContainer);
            }

            weekClone.appendTo($("#promotions .promotionsList"));
        }

        if (AppData.profile.group != "attendee") {
            $("#promotions .promotionsList").append('<div class="promotions_log"><div class="live_log"><div class="title">Foglalások</div><div class="items"></div></div>' +
                '<div class="not_reserved_list"><div class="title">Még nem foglaltak</div><div class="items"></div></div></div>');
        }

        showNotReservedList();
    }

    function reconstruct() {
        promotions = {};
        $.each(AppData.promotions, function (i, item){
            if (promotions[item.week] == undefined) {
                promotions[item.week] = [];
            }

            promotions[item.week].push(item);
        });
    }

    function sort() {
        AppData.promotions.sort(function (a, b) {
            if (a.sort_id !== undefined && b.sort_id !== undefined) {
                return parseInt(a.sort_id) > parseInt(b.sort_id) ? 1 : -1;
            } else {
                return 0;
            }
        });

        reconstruct();
    }

    function refresh(promos) {
        AppData.promotions = promos.promotions;

        $.each(AppData.promotions, function (i, item){

            let ureserved = 0;
            for (let r in AppData.user_promotions) {
                if (AppData.user_promotions[r].promotion_id == item.id) {
                    ureserved += parseInt(AppData.user_promotions[r].quantity);
                }
            }

            let pitem = $("#promotions .promotionsList .promotionItem[data-id='"+item.id+"']");
            pitem.find('.ad_quantity').html(item.available);

            if (parseInt(item.available) <= 0) {
                pitem.addClass("no_more");
            }

            if (ureserved > 0) {
                pitem.addClass("has_own");
            }

            pitem.find('.ad_reserved').html(ureserved);
        });

        if (AppData.profile.group != "attendee") {

            let promo = AppData.promotions.find(function (item) {
                return item.id == promos.data.pid;
            });

            let text = '<b>'+promos.data.name[localState.lang]+', '+promos.data.company[localState.lang]+'</b><br>'+promo.week+'. '+Language.getItem("week")+', '+promo.name[localState.lang]+', '+promos.data.quantity+ ' db<br>'+promos.data.comment;

            $("#promotions .promotionsList .promotions_log .live_log .items").prepend('<div class="log_item">'+text+'</div>');

            showNotReservedList(promos.data);
        }

        reconstruct();
    }

    function showNotReservedList(data) {
        if (AppData.profile.group != "attendee") {
            if (data != undefined) {
                let user_to_remove = AppData.reserved_users.not_reserved.map(function(item) { return item.id; }).indexOf(data.uid);
                if (user_to_remove > -1) {
                    AppData.reserved_users.not_reserved.splice(user_to_remove, 1);
                }
            }

            $("#promotions .promotionsList .promotions_log .not_reserved_list .items").html('');
            $.each(AppData.reserved_users.not_reserved, function (i, item){
                let text = item.company[localState.lang]+", "+item.name[localState.lang];
                $("#promotions .promotionsList .promotions_log .not_reserved_list .items").prepend('<div class="log_item">'+text+'</div>');
            });

            if (data == undefined) {
                $.each(AppData.reserved_users.reserved, function (i, item) {
                    let promo = AppData.promotions.find(function (el) {
                        return el.id == item.pid;
                    });
                    let text = '<b>' + item.name[localState.lang] + ', ' + item.company[localState.lang] + '</b><br>' + promo.week + '. ' + Language.getItem("week") + ', ' + promo.name[localState.lang] + ', ' + item.quantity + ' db<br>'+item.comment;
                    $("#promotions .promotionsList .promotions_log .live_log .items").prepend('<div class="log_item">' + text + '</div>');
                });
            }
        }
    }

    function update() {
        $.post('api/', {
            do: 'getPromotions'
        }, function (data) {
            AppData.promotions = data.promotions;
            AppData.user_promotions = data.user_promotions;
            if (AppData.profile.group != "attendee") {
                AppData.reserved_users = data.reserved_users;
            }
            render();
            data = null;
        });
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        render: render,
        update: update,
        refresh: refresh
    }
})();
