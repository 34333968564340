/*
 * Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
'use strict';
/* ----- Global Variables ------------------------------------------------------------------------------------------- */
/* localState declared in /src/shared/common.js */
localState.layout = localState.layout || [];
//localState.lang = localState.lang || 'hu';
/* ----- Hold Ready ------------------------------------------------------------------------------------------------- */
//$.holdReady(true);

checkReady();

/* Load Client Fragment Content */
$.get(url_rewrite + 'modules/mod_client.html', _ajaxReadyModClient);

function _ajaxReadyModClient(data) {
    $('.bodycontent').html(data);
    $('div.main').hide();
    is_ready.html = true;
    //$.holdReady(false);
}

function checkReady() {
    if (is_ready.config && is_ready.html) {
        clearTimeout(waitForReady);
        //$.holdReady(false);
        sharedReady();
        clientReady();
    } else {
        waitForReady = setTimeout(function (){
            checkReady();
        }, 100);
    }
}

let clientEventListeners = [];
let hide_emojis;
let allow_hide = true;

/* ----- Process on Document Ready ---------------------------------------------------------------------------------- */
function clientReady() {
    /* Init Core */
    Layout.Init();
    Language.Init();
    if (!Settings.Init()) {
        return;
    }

    Navigation.Init();
    /* Init Modules */
    Modal.Init();
    Game.Init();
    Agenda.Init();
    Speakers.Init();
    Useful.Init();
    Docs.Init();
    Channels.Init();
    //Surveys.Init();
    Quiz.Init();
    Attendees.Init();
    Profile.Init();
    Qrcode.Init();
    Roomlist.Init();
    ChangePass.Init();
    Notes.Init();
    Points.Init();
    Moderate.Init();
    Stage.Init();
    Issues.Init();
    Bookings.Init();
    Weblinks.Init();
    Messageboard.Init();
    Namecards.Init();
    Photos.Init();
    Broadcast.Init();
    Chat.Init();
    FavoriteAgenda.Init();
    Gdpr.Init();
    Translator.Init();
    Soundchannels.Init();
    Gallery.Init();
    Camerastream.Init();
    Notifications.Init();
    Trueconf.Init();
    Unity3d.Init();
    Ondemandvideos.Init();
    TranslatorRelay.Init();
    Texts.Init();
    Tetris.Init();
    Modulsettings.Update();
    Promotions.Init();

    setHomeScreenVideo();

    if (AppData.profile.presi_control == 1) {
        //$(".presentation").remove();
        SpeakerPresentation.Init();
    } else {
        $(".speaker_presentation").remove();
    }

    Presentation.Init();

    startEnigma();
    startCatchit();

    /* Deferred Functions */
    $(document).on('animationend', lazyImages);

    var observerEnigma = new MutationObserver(function(mutationsList, observer) {
        for(var m in mutationsList) {
            if ($(mutationsList[m].target).attr("id") == "enigma") {
                if(!$(mutationsList[m].target).is(":visible")) {
                    enigmaGame.reset_game();
                }
            }
        }
    });

    observerEnigma.observe($("#enigma").get(0), { attributes: true, childList: true, subtree: true });

    var observerCatchit = new MutationObserver(function(mutationsList, observer) {
        for(var m in mutationsList) {
            if ($(mutationsList[m].target).attr("id") == "catchit") {
                if(!$(mutationsList[m].target).is(":visible")) {
                    catchit_game.hideGame();
                }
            }
        }
    });

    observerCatchit.observe($("#catchit").get(0), { attributes: true, childList: true, subtree: true });

    var observerWeblinks = new MutationObserver(function(mutationsList, observer) {
        for(var m in mutationsList) {
            if ($(mutationsList[m].target).attr("id") == "weblinks-details") {
                if(!$(mutationsList[m].target).is(":visible")) {
                    clearTimeout(Weblinks.loadtimer);
                    $("#weblinksIframeId").unbind("load");
                    $("#weblinksIframeId").attr("src", "");
                }
            }
        }
    });

    observerWeblinks.observe($(".bodycontent > #weblinks-details").get(0), { attributes: true, childList: true, subtree: true });

    var observerTetris = new MutationObserver(function(mutationsList, observer) {
        for(var m in mutationsList) {
            if ($(mutationsList[m].target).attr("id") == "tetris") {
                if(!$(mutationsList[m].target).is(":visible")) {
                    Tetris.pauseGame();
                }
            }
        }
    });

    observerTetris.observe($("#tetris").get(0), { attributes: true, childList: true, subtree: true });

    //let v_ti = $(".videostream, .trueconf");
    let v_ti = $("#landing");
    let sr = $(".stream_reactions");

    v_ti.on("mouseover touchend", function(){
        if (AppData.modulsettings.show_emoji_reactions && $(".videostream, .trueconf").is(":visible")) {
            $(".stream_reactions").removeClass("hidden");
            clearTimeout(hide_emojis);
            hide_emojis = setTimeout(hideEmojis, 2000);
        }
    });

    v_ti.on("mousemove", function(){
        clearTimeout(hide_emojis);
        if (AppData.modulsettings.show_emoji_reactions && $(".videostream, .trueconf").is(":visible")) {
            $(".stream_reactions").removeClass("hidden");
            hide_emojis = setTimeout(hideEmojis, 2000);
        }
    });

    v_ti.on("mouseleave", function(){
        if (AppData.modulsettings.show_emoji_reactions && $(".videostream, .trueconf").is(":visible")) {
            clearTimeout(hide_emojis);
            hide_emojis = setTimeout(hideEmojis, 2000);
        }
    });

    sr.on("mouseover", function(){
        allow_hide = false;
    });

    sr.on("mouseleave", function(){
        allow_hide = true;
    });
}

function refreshDays() {
    $.post('api/', {
        do: 'getDays'
    }, function (data) {
        AppData.days = data;
        Agenda.BuildFromSource();
        Issues.BuildFromSource();
        Layout.hide("issues-details");
    });
}

function fakeReactions(type) {
    if (AppData.modulsettings.allowreactions && randInt(0, 100) > 30) {
        setTimeout(function () {
            //if (randInt(0, 100) > 50) {
            if (type != undefined) {
                if (type == "laugh") {
                    $("#landing .reactions .laugh").trigger("click");
                } else if (type == "clap") {
                    $("#landing .reactions .clap").trigger("click");
                }
            } else {
                if (randInt(0, 100) > 50) {
                    $("#landing .reactions .laugh").trigger("click");
                } else {
                    $("#landing .reactions .clap").trigger("click");
                }
            }
        }, randInt(100, 400));
    }
}

var trueconf_opened = false;
var trueconf_translator_opened = false;

function openTrueConfCall(room, server, channel, new_window, translator) {
    $.ajax({
        method: 'post',
        url: 'api/',
        data: {
            do: 'openTrueConfCall',
            room: room,
            server: server,
            guestname: AppData.profile.name[localState.lang]
        },
        success: function (response) {
            if (response.trueconf_url != "") {
                writeDbLog("open trueconf room", response.trueconf_url, "user_logs", "trueconf");
                if (new_window === true) {
                    window.open(response.trueconf_url, "chat_" + room);
                } else {
                    if (translator == true) {
                        $("#landing .landing_elements .trueconf_translator").show();
                        $("#trueconf_iframe_translator").attr("src", response.trueconf_url);
                        trueconf_translator_opened = true;
                    } else {
                        $("#landing .landing_elements .trueconf").show();
                        $("#trueconf_iframe").attr("src", response.trueconf_url);

                        if (AppData.modulsettings.show_emoji_reactions) {
                            //$(".stream_reactions").appendTo($(".landing_elements .trueconf"));
                            $(".stream_reactions").removeClass("hidden");
                        } else {
                            $(".stream_reactions").addClass("hidden");
                        }

                        trueconf_opened = true;
                    }

                    Layout.hideAll();
                    Broadcast.stopVideoStream();
                    Navigation.hideMenu();
                }
                if (channel != "" && channel != undefined) {
                    Channels.setChannel(channel)
                }
            } else {
                Modal.alert(Language.getItem("conference-not-available"));
                $("#default_nav").find("[data-target='trueconf']").css("border", '');
            }
        }
    });
}

$(document).on("click", ".trueconf-button.hangup", function (){
    let type = $(this).attr("data-type");
    closeTrueConfCall(type);
});

window.addEventListener('message', (e) => {
    if (e.origin === 'https://meeting2.rentit.hu' || e.origin === 'https://meeting3.rentit.hu' || e.origin === 'https://meeting5.rentit.hu' || e.origin === 'https://meeting6.rentit.hu') {
        console.log(e);
        if (e.data.type === 'connectionClosed') {
            closeTrueConfCall("default");
        }
    }

    if (e.data.type === "open3D") {
        Unity3d.load3DPlayer(e.data.scene || "default", e.data.url);
    }

    if (e.data.type === "callRoom") {
        closeTrueConfCall();
        openTrueConfCall(e.data.room, 'workshop');
    }

    if (AppData.settings.infopanel_url.indexOf(e.origin) > -1) {
        if (e.data.type === "openTrueConf") {
            Unity3d.call_exhibitor(e.data.room);
        }

        if (e.data.type === "openChat") {
            Unity3d.openTextChat(e.data.uid);
        }

        if (e.data.type === "fisu_dwf_360") {
            //hungary_promo_videos
            //welcome
            //universiade_2021
            Ondemandvideos.showList(e.data.target);
        }

        if (e.data.type === "open_eep") {
            var url = AppData.settings.infopanel_url + '/' + e.data.target;
            url += "?pid="+encodeURIComponent(btoa(AppData.profile.id));

            Weblinks.showPortal(url);
        }
    }
});

let from3d = false;

function closeTrueConfCall(type) {
    if (trueconf_opened && type == "default") {
        $("#trueconf_iframe").attr("src", "");
        $("#landing .landing_elements .trueconf").hide();
        $("#default_nav").find("[data-target='trueconf']").css("border", '');
        trueconf_opened = false;
    }

    if (trueconf_translator_opened && type == "translator") {
        $("#trueconf_iframe_translator").attr("src", "");
        $("#landing .landing_elements .trueconf_translator").hide();
        $("#default_nav").find("[data-target='trueconf']").css("border", '');
        trueconf_translator_opened = false;
    }

    if (from3d !== false && type != "3d") {
        trueconf_opened = false;
        Unity3d.load3DPlayer("default", from3d);
    }

    $(".stream_reactions").addClass("hidden");
}

function logVideoWatch(action, stream_url) {
    $.ajax({
        method: 'post',
        url: 'api/',
        data: {
            do: 'logVideoWatch',
            action: action,
            stream_url: stream_url
        }
    });
}

$(document).on('click', '.stream_reactions .reaction_emoji', function (e) {
    let icon = $(this).attr("data-type");

    writeDbLog(icon, localState.currentChannel, 'user_logs');

    Socket.Send("sendStreamReaction", {icon: icon, own: false}, {
        channel: localState.currentChannel
    });

    sendStreamReaction({icon: icon, own: true});
});

function hideEmojis() {
    if (allow_hide && !$(".trueconf").is(":visible")) {
        $(".stream_reactions").fadeOut(800, function () {
            $(this).removeAttr("style").addClass("hidden");
        });
    }
}

function sendStreamReaction(data) {
    let element;
    if ($(".videostream").is(":visible")) {
        element = $(".videostream");
    }

    if ($("#trueconf_iframe").is(":visible")) {
        element = $("#trueconf_iframe");
    }

    if (element != undefined) {
        let reaction = $("<span/>");
        let emoji = $(".stream_reactions [data-type='"+data.icon+"']").html();

        reaction.html(emoji);
        reaction.appendTo($(".landing_elements"));
        reaction.addClass("animated_reaction");

        let left, top;
        if (data.own) {
            if (AppData.modulsettings.emoji_pos == "left") {
                reaction.addClass("left");
                top = $(".stream_reactions [data-type='" + data.icon + "']").offset().top + ($(".stream_reactions [data-type='" + data.icon + "']").height() / 2) - (reaction.height() / 2) - $(".landing_elements").offset().top;
                reaction.css({
                    top: top
                });
            } else if (AppData.modulsettings.emoji_pos == "top") {
                reaction.addClass("top");
                left = $(".stream_reactions [data-type='" + data.icon + "']").offset().left + ($(".stream_reactions [data-type='" + data.icon + "']").width() / 2) - (reaction.width() / 2);
                reaction.css({
                    left: left
                });
            } else {
                left = $(".stream_reactions [data-type='" + data.icon + "']").offset().left + ($(".stream_reactions [data-type='" + data.icon + "']").width() / 2) - (reaction.width() / 2);
                reaction.css({
                    left: left
                });
            }
        } else {
            let half_window = $(window).width() / 2;
            left = half_window + randInt(-half_window / 2, half_window / 2);

            reaction.css({
                left: left
            });
        }



        setTimeout(function (){
            reaction.remove();
        }, 1200);
    }
}

function refreshModule(data) {
    //let capitalizedModule = data.module.charAt(0).toUpperCase() + data.module.slice(1);

    if (AppData[data.appdata] != undefined) {
        AppData[data.appdata] = data.use_lang ? data.data[localState.lang] : data.data;
    }

    for(let i in data.module) {
        if (window[data.module[i]] != undefined) {
            if (typeof window[data.module[i]][data.callback[i]] === "function") {
                window[data.module[i]][data.callback[i]].apply(null, data.callback_params ? [data.callback_params[i]] : null);
            }
        }
    }
}

function updateModifiedContent(data) {
    let has_updated = false;
    let capitalizedModule = data.module.charAt(0).toUpperCase() + data.module.slice(1);

    if (data.only_callback === true) {
        if (typeof window[capitalizedModule][data.callback] === "function") {
            window[capitalizedModule][data.callback].apply(null, [data.callback_params] || null);
        }
        return;
    }

    if (AppData[data.module] != undefined) {
        $.each(AppData[data.module], function (i, el) {
            if (el[data.id_field] == data.id_value) {
                AppData[data.module][i] = data.updated_content;
                has_updated = true;
            }
        });

        if (has_updated && data.callback != undefined) {
            if (typeof window[capitalizedModule][data.callback] === "function") {
                window[capitalizedModule][data.callback].apply(null, [data.callback_params] || null);
            }
        }
    }
}

/* ----- Lazy Load Images ------------------------------------------------------------------------------------------- */
function lazyImages() {
    var $images = $('img[data-src]:visible:lt(1)');
    if ($images.length) {
        $images
            .attr({
                src: function () {
                    return $(this).attr('data-src');
                },
                'data-src': null
            });
        $images = null;
        requestAnimationFrame(lazyImages);
    }
}

/* ----- nl2br ------------------------------------------------------------------------------------------------------ */
function nl2br(input) {
    return String(input)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/(\r\n|\n\r|\r|\n)/g, '<br>' + '$1');
}

function nl2brStrict(input) {
    return String(input)
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/(\r\n|\n\r|\r|\n)/g, '<br>' + '$1');
}

function nl2brOnly(input) {
    return String(input)
        .replace(/(\r\n|\n\r|\r|\n)/g, '<br>' + '$1');
}

function makeTimeFormat(time, timeformat) {
    var newTime,
        ampm;

    if(time != "") {
        if (timeformat === "12") {
            if (parseInt(time) >= 13) {
                newTime = "0" + (parseInt(time) - 12) + time.substr(time.indexOf(":"));
            } else {
                newTime = time;
            }

            if (parseInt(time) >= 12) {
                ampm = "PM";
            } else {
                ampm = "AM";
            }
            return newTime + ampm;
        }
    }
    return time;
}

/* ----- Log Out ---------------------------------------------------------------------------------------------------- */
$(document).on('click', '.logout', logOut);

function logOut() {
    Modal.confirm(Language.getItem('logout-text'), function () {
        writeDbLog("logout", "","user_logs", "logout");
        $.post('api/', {
            do: 'logOut'
        });

        if (typeof window.webkit !== "undefined") {
            window.webkit.messageHandlers.logOut.postMessage('logout');
        }

        if (typeof Android !== "undefined") {
            Android.logout();
        }
    });
}

function forceLogOut() {
    writeDbLog("force logout", "", "user_logs", "logout");
    $.post('api/', {
        do: 'logOut'
    });

    if (typeof window.webkit !== "undefined") {
        window.webkit.messageHandlers.logOut.postMessage('logout');
    }

    if (typeof Android !== "undefined") {
        Android.logout();
    }
}

function setHomeScreenVideo() {

    var streamplayer = videojs('my-video-bg');
    if (AppData.settings.showHomeScreenVideo) {
        if ('homescreenVideo' in AppData.settings) {
            let fg = AppData.medias.find(function (item){
                return item.id === AppData.settings.homescreenVideo;
            });
            if (fg && fg.files[localState.lang].file != undefined) {
                if (!streamplayer.paused) {
                    streamplayer.pause();
                }
                streamplayer.src(fg.files[localState.lang].file);
                streamplayer.load();
                streamplayer.play();
                $("#playerElementBackground").show();
            }
        }
    } else {
        streamplayer.paused(true);
        $("#playerElementBackground").hide();
    }


}